import React, { useEffect } from 'react';
import { Text, Button, Input, Flex, Alert, AlertIcon } from '@chakra-ui/react';
import Locale from '../../../utils/translations';
import { BonfireAPI, ApiType } from '../../../models/BonfireApi';

const SpeechToSpeechFlow: React.FC = () => {
	const [findSessionId, setFindSessionId] = React.useState<string>('');
	const [sessions, setSessions] = React.useState<any[]>([]);

	const renderSession = (sessionId: string) => {
		document.location.href = `/s2s-flow/${sessionId}`;
	};

	const renderSessionChunks = (sessionId: string) => {
		document.location.href = `/s2s-flow/chunks/${sessionId}`;
	};

	const renderSessionSimulations = (sessionId: string) => {
		document.location.href = `/s2s-flow/simulations/${sessionId}`;
	};

	const getSessions = async () => {
		try {
			const api = new BonfireAPI(ApiType.QaApi);
			const sessionsData = await api.get('s2sflow/getSessions');
			setSessions(sessionsData);
		} catch (error) {
			console.error(error);
		}
	};

	const findSession = async () => {
		if (findSessionId === '') {
			getSessions();
			return;
		}

		try {
			const api = new BonfireAPI(ApiType.QaApi);
			const sessionData = await api.post('s2sflow/findSessionById', {
				sessionId: findSessionId,
			});
			if (!sessionData || sessionData.error) {
				setSessions([]);
				return;
			}
			setSessions([sessionData]);
		} catch (error) {
			console.error(error);
			setSessions([]);
		}
	};

	useEffect(() => {
		getSessions();
	}, []);

	return (
		<Flex flexDirection={'column'} width={'100%'}>
			<Flex
				flexDirection="row"
				width={'500px'}
				justifyContent={'center'}
				alignItems={'center'}
			>
				<Input
					pr={2}
					shadow="0px 4px 4px 0px rgba(74, 74, 74, 0.04)"
					fontWeight="400"
					variant={'beVariant'}
					placeholder={'Session ID'}
					_placeholder={{ fontWeight: 'normal' }}
					value={findSessionId}
					onChange={e => {
						setFindSessionId(e.target.value);
					}}
				/>
				<Button ml={2} size="sm" variant={'bePrimary'} onClick={() => findSession()}>
					{Locale.get('Search')}
				</Button>
			</Flex>
			<Text mt={5} fontSize={30} fontWeight={'bold'}>
				Sessions
			</Text>
			<Flex width={'100%'} mt={5} flexDirection={'column'}>
				{sessions?.length > 0 &&
					sessions.map(session => (
						<Flex
							key={session.sessionId}
							backgroundColor={'#fff'}
							borderWidth={1}
							borderColor={'#ccc'}
							width={'100%'}
							height={'80px'}
							flexDirection={'row'}
							alignItems={'center'}
							justifyContent={'flex-start'}
							mb={2}
						>
							<Flex flex={1} flexDirection={'column'} ml={5}>
								{session.processStatus === 'locked' && (
									<Alert
										status="warning"
										width="fit-content"
										display="inline-flex"
										py="1"
									>
										<AlertIcon />
										<Text fontWeight={'bold'}>
											Session is being processed by another user.
										</Text>
									</Alert>
								)}{' '}
								<Text fontSize={18} fontWeight={'bold'}>
									{session.sessionId} ({session.client})
								</Text>
							</Flex>
							<Flex>
								<Button
									isDisabled={session.processStatus === 'locked'}
									onClick={() => {
										renderSessionChunks(session.sessionId);
									}}
								>
									Chunks
								</Button>
								<Button
									isDisabled={session.processStatus === 'locked'}
									ml={3}
									mr={3}
									onClick={() => {
										renderSession(session.sessionId);
									}}
								>
									Visualization
								</Button>
								<Button
									onClick={() => {
										renderSessionSimulations(session.sessionId);
									}}
								>
									Simulations
								</Button>
							</Flex>
						</Flex>
					))}
				{sessions?.length === 0 && (
					<Flex width={'100%'} alignItems={'center'} justifyContent={'center'}>
						<Text ml={5} fontSize={18} fontWeight={'bold'}>
							No sessions found
						</Text>
					</Flex>
				)}
			</Flex>
		</Flex>
	);
};

export default SpeechToSpeechFlow;
